import { Pipe, PipeTransform } from '@angular/core';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import debug from 'debug';
import { empty, Observable, of, timer } from 'rxjs';
import { expand, map, skip, startWith } from 'rxjs/operators';

const log = debug('cs:AgoPipe');

const defaultOptions = {
  includeSeconds: false,
  addSuffix: true,
};

export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

@Pipe({
  name: 'ago',
  pure: true,
  standalone: true,
})
export class AgoPipe implements PipeTransform {
  transform(
    value: string,
    options: {
      addSuffix?: boolean;
      includeSeconds?: boolean;
    } = defaultOptions
  ) {
    return tick(dateParser(value).valueOf()).pipe(
      startWith(0),
      map(() =>
        formatDistanceStrict(new Date(value), new Date(), {
          ...options,
        })
      )
    );
  }
}

function dateParser(date: any): Date {
  const parsed = new Date(date);
  if (!Number.isNaN(parsed.valueOf())) {
    return parsed;
  }

  const parts = String(date).match(/\d+/g);
  if (parts === null || parts.length <= 2) {
    return parsed;
  } else {
    const [firstP, secondP, ...restPs] = parts.map((x) => parseInt(x, 10));
    return new Date(Date.UTC(firstP, secondP - 1, ...restPs));
  }
}

// https://github.com/ihym/ngx-timeago/blob/master/lib/src/timeago.pipe.ts
function tick(then: number): Observable<any> {
  // log('tick', then);
  return of(0).pipe(
    expand(() => {
      const now = Date.now();
      const seconds = Math.round(Math.abs(now - then) / 1000);

      const period =
        seconds < HOUR ? 1000 * MINUTE : seconds < DAY ? 1000 * HOUR : 0;

      period
        ? log(`${then}: updating every ${period / 1000}s`)
        : log(`${then}: never updating`);
      return period ? timer(period) : empty();
    }),
    skip(1)
  );
}
